.table {
	border-collapse: collapse;
	background: rgb(0,0,0,0);
	border-radius: 6px 6px;
	font-size: 0.8rem;
}
.table>tbody>tr>td {
	vertical-align: middle;
}
.table>thead>tr>th {
	border: none;
}
.table thead th {
	border-top: 0 !important;
}

.project-table {
	border-radius: 6px 6px;
	margin: 1rem;
	margin-bottom: 5rem;
	/*overflow-y: auto;*/
}
.project-table thead th {
	position: sticky;
	top: 0;
}
.table-title {
	background: #eee;
	vertical-align: middle !important;
	font-size: 1rem;
	z-index: 1;
}
.table-body tr:last-child td:first-child {
	border-bottom-left-radius: 6px;
}
.table-body tr:last-child td:last-child {
	border-bottom-right-radius: 6px;
}
.firstrow td {
	padding-top: 1rem !important;
}
.leftrow {
	border-top-left-radius: 6px;
}
.status {
	border-top-right-radius: 6px;
}
.sortTitle {
	display:inline-block;
}
.sortTitle:active,
.sortTitle:hover {
	background-color: #F6B924;
	padding: 0.5rem 0.75rem;
	border-radius: 6px 6px;
	cursor: pointer;
	display:inline-block;
}
.sortSelect {
	background-color: #F6B924;
	padding: 0.5rem 0.75rem;
	border-radius: 6px;
	display:inline-block;
	color: white;
}
.status:active,
.status:hover {
	text-decoration: none;
	color: black;
}
.table-row {
	background: white;
	border-radius: 6px 6px;
	cursor: pointer;
}
.table-row:hover {
	background: rgba(211, 211, 211, 1.0);
}
.table-row.read:hover {
	background: white;
	cursor: auto;
}
@media only screen and (min-width: 38rem) and (max-width: 53.75rem) {
	.project-search {
		width: 60%;
	}
	
}
@media only screen and (max-width: 38rem) {
	.project-header {
		justify-content: space-evenly;
		zoom: 0.65;
	}
	.table {
		zoom: 0.65;
	}
}
@media only screen and (max-width: 49rem) {
	.delete {
		display: none;
	}
}
@media only screen and (max-width: 34rem) {
	.table {
		margin-bottom: 0;
	}
}
button:focus {
	outline: none !important;
}
.h1, .h2, .h3, h1, h2, h3 {
	margin: 0 !important;
}

.input-icon {
  position: relative;
}

.input-icon > input {
  padding-left: 20px;
  padding-right: 0;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

* {
	box-sizing: border-box;
	margin: 0;
}
*:focus {
	outline: none;
}
.container1 {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 64px);
	overflow: scroll;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	-webkit-overflow-scrolling: touch;
}

@media(max-width: 960px) {
	.container1 {
		height: calc(100vh - 9rem);
	}
}

.project-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2.5rem;
	font-size: 1rem;
	font-weight: bold;
	color: #c9ccca;
	background: rgb(0, 62, 126);
	z-index: 200;
}
.project-return1 {
	flex-shrink: 0;
	display: flex;
	align-items: center;
}
.project-title {
	padding: 1rem 1rem;
	flex-shrink: 0;
	flex-grow: 0;
	font-weight: bold;
}
.project-return {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50% 50%;
	border: none;
	height: 3rem;
	width: 3rem;
	background: white;
}
.project-return-button {
	transform: scale(1.5, 1.5);
}
.project-search {
	background: white;
	border-radius: 22px;
	border: none;
	padding: 0.2rem 0.8rem;
	width: 15rem;
	flex-shrink: 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.project-searcher {
	background: white;
	border-radius: 22px;
	border: none;
	padding: 0.2rem 0rem;
	padding-left: 0.8rem;
	width: 94%;
	color: black;
	height: 2.25rem;
}
.magnify {
	transform: scale(1.7, 1.7);
}
.project-commands {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-evenly;
	padding: .5rem 1rem;
	flex-shrink: 0;
	width: 200px;
}
.download {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 0.5rem;
	border-radius: 50% 50%;
	background: white;
	height: 3rem;
	width: 3rem;
}
.download-button {
	transform: scale(1.5, 1.5);
	fill: black;
}
.download {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0rem 1rem 0rem 2rem;
	border-radius: 2rem 2rem;
	background: rgb(0, 62, 126);
	height: 3rem;
	color: white;
	cursor: pointer;
	width: fit-content;
	align-self: flex-end;
}
.contract-download-button {
	transform: scale(1.5, 1.5);
	fill: white;
}
.contract-download-text {
	padding: 1rem;
	white-space: nowrap;
}
.contract-download:active,
.contract-download:hover {
	transform: scale(1.04);
}
.contract-redirect {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0rem 1rem 0rem 1rem;
	border-radius: 6px;
	background: rgb(0, 62, 126);
	height: 3rem;
	color: white;
	cursor: pointer;
	width: fit-content;
	align-self: flex-end;
	border: none;
	position: relative;
}

.contract-redirect:hover {
	text-decoration: none;
	color: white;
}
.contract-redirect:active,
.contract-redirect:hover {
	transform: scale(1.04);
}
.save {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0rem 1rem 0rem 1rem;
	border-radius: 6px;
	background: #5cb85c;
	height: 3rem;
	color: white;
	cursor: pointer;
	width: fit-content;
}
.save-button {
	transform: scale(1.5, 1.5);
	fill: white;
}
.save-text {
	padding: 0.75rem;
	font-size: 1.2rem;
	white-space: nowrap;
	margin: 0;
}
.save:active,
.save:hover {
	transform: scale(1.04);
}
.delete-btn {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0rem 1rem 0rem 1rem;
	border-radius: 6px;
	background: #d9534f;
	height: 3rem;
	color: white;
	cursor: pointer;
	width: fit-content;
	margin-left: 1.5rem;
}
.delete-button {
	transform: scale(1.5, 1.5);
	fill: white;
	margin: 0;
}
.delete-text {
	padding: 0.75rem;
	font-size: 1.2rem;
	white-space: nowrap;
}
.delete-btn:active,
.delete-btn:hover {
	transform: scale(1.04);
}
.btn-group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.sorter {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.5rem;
	border-radius: 50% 50%;
	background: white;
	height: 3rem;
	width: 3rem;
}
.sort-button {
	transform: scale(1.5, 1.5);
	fill: black;
}
.sort-true {
	fill: #F6B924;
}
.project-new {
	display: flex;
	align-items: center;
}
.add {
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50% 50%;
	background: #F6B924;
	border-color: #F6B924;
	fill: white;
	height: 3rem;
	width: 3rem;
}
.add-button {
	transform: scale(1.5, 1.5);
}
.project-new:active,
.project-new:hover {
	transform: scale(1.14);
	border: none;
}
.project-return:active,
.project-return:hover {
	transform: scale(1.14);
}
.download:active,
.download:hover,
.sorter:active,
.sorter:hover {
	transform: scale(1.14);
  cursor: pointer
}



.table {
	border-collapse: collapse;
	background: rgb(0,0,0,0);
	border-radius: 6px 6px;
	font-size: 0.8rem;
}
.table thead th {
	border-top: 0 !important;
}
.project-table {
	border-radius: 6px 6px;
	margin: 1rem;
	margin-bottom: 5rem;
}
.project-table thead th {
	position: sticky;
	top: 0;
}
.table-title {
	background: #eee;
	vertical-align: middle !important;
	font-size: 1rem;
}
.table-body tr:last-child td:first-child {
	border-bottom-left-radius: 6px;
}
.table-body tr:last-child td:last-child {
	border-bottom-right-radius: 6px;
}
.firstrow td {
	padding-top: 1rem !important;
}
.leftrow {
	border-top-left-radius: 6px;
}
.status {
	border-top-right-radius: 6px;
}
.sortTitle {
	display:inline-block;
}
.sortTitle:active,
.sortTitle:hover {
	background-color: #F6B924;
	padding: 0.5rem 0.75rem;
	border-radius: 6px 6px;
	cursor: pointer;
	display:inline-block;
}
.status:active,
.status:hover {
	text-decoration: none;
	color: black;
}
.table-row {
	background: white;
	border-radius: 6px 6px;
}
.table-row:hover {
	background: rgba(211, 211, 211, 1.0);
}
.row-img {
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	background: white;
	display: flex;
	justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 1rem;
}
.img-row {
	display: flex;
	align-items: center;
}
.row-img-src {
	object-fit: cover;
	width: 100%;
}
.row-img-td {
	width: 2rem !important;
}
@media only screen and (min-width: 38rem) and (max-width: 53.75rem) {
	.project-search {
		width: 60%;
	}
	
}
@media only screen and (max-width: 38rem) {
	.project-header {
		justify-content: space-evenly;
		zoom: 0.65;
		
	}
	.table {
		zoom: 0.65;
	}
}
@media only screen and (max-width: 49rem) {
	.delete {
		display: none;
	}
}
@media only screen and (max-width: 34rem) {
	.table {
		margin-bottom: 0;
	}

}
.background-list {
	background-image: linear-gradient(to top, rgb(220,220,220, 0.3) 0.5%, rgb(40,40,40, 0.3));
	/*background: #E0E0E0;*/
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: -1;
}
button:focus {
	outline: none !important;
}
.h1, .h2, .h3, h1, h2, h3 {
	margin: 0 !important;
}

.input-icon {
  position: relative;
}

.input-icon > input {
  padding-left: 20px;
  padding-right: 0;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}
.year-select {
	display: flex;
	width: 50%;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 1rem;
}
.year-label {
	font-weight: 550;
	font-size: 2rem;
	margin-right: 2rem !important;
	color: white;
}
.year-dropdown-cont {
	width: 50%;
}

