.error-body {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.error {
	background: rgb(0, 62, 126,0.9);
	padding: 3rem 3.5rem;
	border-radius: 20px;
	margin: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	color: #c9ccca;
	text-align: center;
	box-shadow: 3px 5px 5px white;
}

.alert {
	position: fixed;
	width: 100vw;
	z-index: 14;
}
