body {
	font-family: 'Helvetica', 'Arial', 'sans-serif' !important;
}

.react-confirm-alert-overlay {
	z-index: 10000 !important;
}

.centered {
	display: flex;
  align-items: center;
  justify-content: center;
}

.home-btn {
	margin: 20px;
	height: auto;
	width: 250px;
}

.overhead-row {
	height: 30px;
	max-height: 35px;
}

.overhead-row:hover {
	background-color: rgba(200, 200, 200, 0.5);
}

.current-overhead-row {
	background-color: rgba(211, 211, 211, 0.5);
}

.date-style {
	width: 500px;
}

.text-area {
	resize: vertical;
	max-height: 150px;
}

.row:before {
   content: none;
}

.add-div {
	position: relative;
	height: 69px;
}

.add-btn {
	position: absolute;
	right: 20px;
	bottom: 10px;
}

.sow-yes {
	color: green;
}

.sow-no {
	color: red;
}

.stat-completed {
	color: black;
}

.stat-not-started {
	color: orange;
}

.stat-in-progress {
	color: green;
}

.stat-pending {
	color: red;
}

.stat-candidate {
	color: purple;
}

.contract-buttons-div {
	position: relative;
	margin: 5mm auto;
	width: 400px;
}

.contract-btn {
	width: 150px;
	margin: 10px;
}

.page-background {
	display: flex;
	flex-direction: column;
	width: 100vw;
	justify-content: center;
	align-items: space-between;
}

.page {
	font-family: "Times New Roman", Times, serif;
	font-size: 1.1em;
	width: 190mm; 
	height: 300mm;
	background: white;
	box-shadow: none !important;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.margins {
	padding: 15mm;
}

.contract-row {
	position: relative;
	top: 0; right: 0; left: 0;
}

.tabbed {
	position: relative;
	margin-right: 10mm;
}

.center-txt {
	text-align: center;
}

.heading {
	margin-top: 10px;
	font-size: 1.6em;
}

.heading2 {
	font-size: 1.3em;
}

.heading3 {
	font-size: 1.15em;
}

.quill {
	position: absolute;
	right: 0; left: 0;
	height: 200px;
}

.quill-div {
	position: relative;
	height: 250px;
}

.cell-box {
	position: relative;
	height: 30px;
	border: 1px solid black;
}

.cell-box2 {
	position: relative;
	height: 50px;
	border: 1px solid black;
}

.cell-box3 {
	position: relative;
	height: 40px;
	border: 1px solid black;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.sortArrow {
	color: #FFB717;
}

.align-right {
	text-align: right;
}

.bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.top-div {
	display: flex; justify-content: flex-end
}

.center {
	text-align: center;
}

.home-card {
	width: 100%;
	height: 300px;
	margin-bottom: 30px;
}

.home-jumbo { 
	height: 100%;
	transition: all .2s ease-in-out; 
	box-shadow: 0 0 6px grey;
}

.home-jumbo:hover { 
	transform: scale(1.02); 
}

.link-card {
	color: black;
}

.link-card:hover {
	color: black;
	text-decoration: none;
}

.card-padding {
	padding: 5px 30px 20px 30px !important;
	margin: 0 !important;
}

/*REAL THINGS BELOW*/

#root {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

*:focus,
*:hover,
*:active {
	outline: none;
}

.MuiInputLabel-shrink {
	transform: translate(0, 1.5px) scale(0.95) !important;
}

.MuiTooltip-tooltip {
	font-size: 1.1rem !important;
}
