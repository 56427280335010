
/*This is the CSS for the Consultant Assignment cards */

.container2 {
	padding: 1rem;
	padding-bottom: 2rem;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: calc(100vh - 64px);
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}
@media(max-width: 960px) {
	.container2 {
		height: calc(100vh - 9rem);
	}
}
.container1 {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 64px);
	overflow: scroll;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	-webkit-overflow-scrolling: touch;
}

@media(max-width: 960px) {
	.container1 {
		height: calc(100vh - 9rem);
	}
}
.assignment {
	margin: 1rem 0 0 1rem;
	width: 25rem;
	height: 37rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
	border-radius: 4px;
	box-shadow: 2px 2px 5px grey;
}
.assignment-header {
	display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2.5rem;
}
.title-client-container {
	display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.assignment-title {
	font-weight: 550;
	flex-shrink: 1;
	flex-grow: 1;
	font-size: 1.6rem;
}
.project-range {
	font-size: 1rem;
	margin: 0;
	padding-top: 0.5rem;
}
.project-status {
	font-size: 0.9rem;
	margin: 0;
	padding-top: 0.5rem;
}
.project-contract-none {
	align-self: center;
	margin: 0;
	font-size: 0.9rem;
}
.hour-rate-icon {
	height: 3rem;
}
.hour-rate-description {
	display: flex;
	align-items: center;
	margin: 0.8rem 0;
}
.hour-rate {
	font-weight: bold;
	font-size: 1.1rem;
}
.hour-line {
	margin: 0;
	margin-left: 0.75rem;
	font-size: 1.1rem;
}
.assignment-sow {
	height: 4rem;
	font-size: 1.5rem;
	align-self: flex-start;
}
.assignment-sow:hover,
.assignment-sow:active {
	transform: scale(1.02);
	background: #b7b5b5;
}
.contract-download {
	margin-left: 1.5rem;
	display: flex;
	align-items: center;
	background: #01579B;
	color: white;
	padding: 0 1rem 0 1rem;
	border-radius: 6px;
	cursor: pointer;
}
.assignment .contract-download {
	align-self: center;
	
}
.assignment-info {
	display: flex;
	padding: 0.5rem 0;
}
.assignment-details {
	padding: 0 2rem 2rem 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.assignment-data-title, 
.assignment-data {
	break-inside: avoid-column;
	font-size: 1rem;
	line-height: 1;
}
.assignment-data {
	font-weight: normal;
	padding-left: 1rem;
	color: #5e5e5e;
	margin: 0;
}
.client-header {
	display: flex;
	padding: 2rem 0rem 0rem 1rem;
}
.client-logo {
	width: 30%;
	display: flex;
	justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 1.5rem;
}
.client-logo-src {
	object-fit: cover;
	width: 100%;
}
.client-name {
	font-weight: bold;
	font-size: 1.3rem;
	padding: 0.5rem 0 0.5rem 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	align-items: center;
	display: flex;
	width: 70%;
}
.client-name-text {
	background: rgb(232, 234, 237);
	padding: 1rem;
	width: 100%;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.manager-name-text {
	font-weight: normal;
	font-size: 1rem;
	padding-top: 0.5rem;
}
.expenses-description {
  font-size: 1rem;
 	font-weight: normal;
 	line-height: 1;
  color: #333333;
  font-weight: 500;
}
.expenses {
  font-size: 1rem;
  font-weight: normal;
  color: #5e5e5e;
  margin: 0;
}
.assignment-btn-container {
	width: 100%;
	height: 6rem;
	background: rgb(232, 234, 237);
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
@media(min-width: 1100px) {
	.assignment-container {
		width: 32%;
	}
}
/*@media(max-width: 63rem) {
	.assignment-container {
		width: 95%;
	}
}*/
@media(max-width: 800px) {
	.assignment {
		width: 95%;
	}
/*	.assignment-title {
		font-size: 3vw;
	}
	.client-name {
		font-size: 2.5vw;
	}
	.client-logo {
		height: 15vw;
		width: 15vw;
		margin-right: 3vw;
	}
	.project-range {
		font-size: 2vw;
	}
	.project-status {
		font-size: 1.8vw;
	}
	.hour-rate, .hour-line {
		font-size: 2.5vw;
	}
	.hour-rate-icon {
		height: 6vw;
	}
	.hour-rate-description {
		margin: 1.9vw 0;
	}
	.assignment {
		padding-top: 5vw;
	}
	.assignment-header {
		margin-left: 2vw;
	}
	.assignment-details {
		margin-left: 2vw;
	}
	.project-contract-none {
		font-size: 2.5vw;
		margin-top: 1rem;
	}*/
}

.card-contract-download {
	display: flex;
	height: 3rem;
	padding: 1rem 1rem;
	align-items: center;
	border-radius: 6px;
	background: #01579B;
	justify-content: space-between;
	margin: 0 0.5rem;
	cursor: pointer;
}
.card-contract-download-button {
	fill: white;
}
.card-contract-download-text {
	font-size: 0.9rem;
	margin: 0;
	padding: 0.5rem !important;
	color: white;
}

/*This is the end of the Consultant Assignment Cards*/


/*This is the start of the inner table form styling on top of the bootstrap*/

.form {
	margin: 0rem;
	padding: 1rem;
	background: #fff;
	border-radius: 6px 6px;
	font-size: 0.8rem;
}
.form-commands {
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;
	padding: 0 1rem;
	align-items: flex-end;
}
.btn-danger {
	margin: 0 2rem;
}
.col-lg-12 {
	padding-left: 0;
}

.form-body1 {
	display: flex;
	flex-direction: column;
}
.form-control:focus {
	border: 1px solid rgb(38,132,255);
	border-radius: 4px;
	box-shadow: 0 0 1px #2684FF;
}
.css-1szy77t-control {
	border-width: 1px !important;
	box-shadow: 0 0 1px #2684FF !important;
	
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
	background-color: #dbd9d9;
}
label {
	padding-top: 0.7rem;
}
.editForm {
	padding: 1rem 0 !important;
}
.container-add {
	margin: 1rem 1rem 0 1rem;
	z-index: 12;
	border-radius: 6px;
	box-shadow: 2px 2px 8px grey;
	position: sticky;
	position: -webkit-sticky;
	top: 1rem;
}
.radio-div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.radio-label {
	padding: 0 !important;
	padding-top: 0.5rem !important;
}
.radio-item {
	display: flex;
	justify-content: space-around;
	width: 30%;
}
.sales-commission-area {
	display: flex;
}
.sales-commission-item {
	flex-grow: 1;
}
.data-form-commands {
	margin-top: 1rem;
}
.db-page-selected {
	font-weight: bold;
}

.db-admin-data-container li:hover {
	font-weight: 625;
}
.form-control {
	height: auto !important;
	font-size: 0.8rem !important;
}
.css-bg1rzq-control {
	min-height: auto !important;
}
.contract-download-text {
	font-size: 1rem;
	margin: 0;
	padding: 0.5rem !important;
}
.save-text,
.delete-text {
	padding: 0.5rem;
	margin: 0;
	font-size: 1rem;
}
.css-16pqwjk-indicatorContainer {
	padding: 0 !important;
}
.delete-button,
.save-button,
.download-button {
	transform: scale(1.2) !important;
}
.delete-btn,
.contract-download,
.save {
	height: 3rem !important;
	margin-top: 1rem;
}

.disabled-save {
	background: rgb(50,50,50,0.5);
	position: absolute;
	padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.save {
	position: relative;
}

.invoice-form {
	font-weight: bold;
}
.invoice-top {
	width: 70vw;
	display: flex;
	justify-content: space-between;
}
.invoice-left {
	display: flex;
	flex-direction: column;
}
.invoice-date-style {
	width: 30vw;
}
.invoice-title {
	width: 100%;
}
.invoice-row {
	margin: 0;
}
.invoice-horiz {
	display: flex;
	align-items: center;
}
.invoice-test-check {
	margin-left: 1rem !important;
}
.col-xs-6 {
	width: 100%;
}
.db-responsive-page-title-mobile {
	display: none;
}
.effective-date-div {
	display: flex;
}
.css-3esr4u-a11yText,
.select__control--is-open  {
	display: none !important;
}

.select__dropdown-indicator {
	min-height: 0 !important;
	height: 20px !important;
}
.css-1thkkgx-indicatorContainer, .select__indicator, .css-1szy77t-control {
	min-height: 0 !important;
	height: 20px !important;
	padding: 0 !important;
}
.select__control--is-focused, .select--is-disabled, .select__control--is-disabled,
.select__control--menu-is-open {
	min-height: 0 !important;
	height: 26px !important;
}
/*This is the end of the inner table form styling on top of the bootstrap*/


/*Additional css for contract form*/

.contract-date {
	font-size: 1rem;
}
.margins {
	display: flex;
	flex-direction: column;
	padding: 15mm;
	box-shadow: 0 4px 8px 0 rgb(0,0,0,0.2), 0 6px 20px 0 rgb(0,0,0,.19);
	margin: 0;
	position: relative;
	width: auto;
	height: auto;
	top: auto;
	right: auto;
	left: auto;
	bottom: auto;
	min-height: 287mm;
}
.address-div {
	display: flex;
	flex-direction: column;
	align-items: space-between;
}
.address-side {
	display: flex;
	justify-content: space-between;
}
.address-item {
	width: 49%;
}
.address-item-3 {
	width: 32%;
}
.contract-list {
	list-style: decimal;
	margin-top: 1rem;
	display: flex;
	align-items: flex-end;
}
.amended-title {
	margin-bottom: 2rem;
}
.contract-list-title {
	margin-right: 1rem;
	font-weight: 600;
}
.contract-amend {
	list-style: decimal;
	display: flex;
	align-items: flex-end;
}
.yes-amend {
	border-radius: 6px;
	background: #F6B924;
	color: white;
	border: none;
	padding: 0.5rem 1rem;
	cursor: pointer;
}
.no-amend {
	align-self: flex-start;
	margin-right: 1rem;
	background: #d9534f;
	color: white;
	padding: 0 0.5rem;
	height: 2.5rem !important;
	width: 2.5rem !important;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
}
.archive-row-btn {
	display: flex;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.archive-btns {
	fill: #fff;
	margin-left: 0.1rem;
}
.archive-btns-div {
	display: flex;
	width: 5rem;
	justify-content: space-between;
	margin-right: 0.5rem;
}
.archive-btns-outer-div {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.staff-box {
	display: flex;
}
.req {
	color: #d9534f;
	font-size: 1.1rem;
	line-height: 1rem;
}
.warning {
	padding: 0;
	margin: 0;
	font-size: 1.2rem;
	line-height: 1rem;
	background: rgb(217,83,79, 0.8);
	color: white;
	padding: 1rem;
	border-radius: 6px;
}
.warning p {
	margin-bottom: 0.5rem;
}
.invalid {

}
.invalid-text {
	padding-top: 0.25rem;
	margin-bottom: 0;
	color: #d9534f;
	font-size: 0.85rem;
}
.select__single-value--is-disabled {
	color: black !important;
}
.loading-div-container-local {
	background: white;
	border-radius: 6px;
	height: 100px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.backdrop-local {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 13;
	background: rgb(0,0,0,0.25);
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.form-control[disabled] {
	background: hsl(0,0%,95%);
	border-color: hsl(0,0%,90%);
}
.disabled-contract {    
	background: rgb(50,50,50,0.5);
	position: absolute;
	padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

/* Below is new expeneses area CSS */

.expenses-type-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.expenses-type-option {
	background: white;
	color: #555;
	cursor: pointer;
	padding: 6px 0;
	text-align: center;
}
.expenses-type-option:hover {
	background: #01579B;
	color: white;
}
.expenses-type-option.selected {
	background: #01579B;
	color: white;
	font-weight: bold;
}
.top-expenses-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
}
.top-expenses-side {
	width: 49%;
}
.fixed-cost-detail-container {
	display: flex;
	justify-content: space-between;
	width: 49%;
}


/* Below is new contract CSS */

.comp-chart {
	font-size: 1.3rem;
	width: 100%;
}
.comp-chart-row {
	display: flex;
	justify-content: space-between;
}
.new-cell {
	border: 1px solid black;
	padding: 0.1rem 0.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

/* Below is sales reps CSS */

.sales-rep-container {
	display: flex;
	flex-direction: column;
}

.sales-rep-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sales-rep-title {
	font-size: 0.8rem;
	padding: 6px 12px 6px 0;
	line-height: 1.42857143;
	background: #01579B;
	color: white;
	border-radius: 4px;
}

.sales-rep-remove {
	margin: 0 6px;
	background: white;
	color: red;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: none;
	padding: 0;
}

.sales-rep-btn {
	font-size: 0.8rem;
	padding: 6px 12px;
	line-height: 1.42857143;
	background: #F6B924;
	color: black;
	border-radius: 4px;
	border: none;
	width: 4rem;
}
.sales-rep-btn:hover {
	color: white;
}


