* {
	box-sizing: border-box;
	margin: 0;
}
*:focus {
	outline: none;
}

/*Start of css for Dashboard and responsive page*/

.db-main-body {
	display: flex;
	height: 100vh;
}
.no-content {
	background: white;
	border-radius: 22px;
	padding: 4rem 2rem;
	position: fixed;
	font-weight: 600;
	font-size: 1.2rem;
	text-align: center;
	max-width: 45vw;
	box-shadow: 14px 14px 14px grey;
}
.db-dashboard-container {
	height: 100vh;
  z-index: 15;
  overflow: hidden;
  color: #8C8C8C;
	box-shadow: 0 0 10px #8C8C8C;
	background: white;
}
.db-dashboard-title {
	margin: 0;
	font-size: 1.25rem;
	padding: 0;
}
.db-dashboard-list {
	padding: 0.5rem 0;
	list-style: none;
	font-size: 1rem;
}
.db-dashboard-list span {
	padding-left: 1rem;
}
.db-dashboard-list span:hover {
	font-weight: 625;
}
.db-dashboard-list li {
	fill: grey;
}
.db-admin-data-dropdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	fill: grey;
}
.db-dashboard-list:hover {
	cursor: pointer;
}
.db-dashboard-list-icon {
	font-size: 1rem;
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	visibility: hidden;
	fill: grey;
}
.db-dashboard-icons {
	width: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.db-dashboard-list-icon li {
	padding: 1rem 0;
}
.db-divider-horizontal {
	height: 1.5px;
	width: 2rem;
	background: grey;
	visibility: hidden;
	margin-bottom: 0.9rem;
	border-radius: 0.75px;
}
.db-admin-container {
	display: flex;
	align-items: center;
}
.db-admin-data-container {
	display: flex;
	flex-direction: column;
}
.db-vertical-divider {
	width: 2.5px;
	height: 5.5rem;
	background: #01579B;
	margin-left: 1.7rem;
}
.db-vertical-divider-projects {
	width: 2.5px;
	height: 5.5rem;
	background: #01579B;
	margin-left: 1.7rem;
}
.db-vertical-divider-archives {
	width: 2px;
	height: 6.6rem;
	background: #01579B;
	margin-left: 1.7rem;
}
.db-vertical-divider-springahead {
	width: 2px;
	height: 4rem;
	background: #01579B;
	margin-left: 1.7rem;
}
.db-profile-section-inner {
	cursor: pointer;
}
.db-admin-data {
	padding-left: 0.4rem;
	color: grey;
}
.db-admin-data.title {
	padding-left: 1rem;
}
.db-admin-data:active,
.db-admin-data:hover,
.db-admin-data:focus {
	font-weight: bold;
	color: grey;
}
.phone-hide {
	display: block;
}
@media only screen and (max-device-width: 812px) {
	.db-responsive-page-title-mobile {
		display: block !important;
		font-size: 1.2rem;
	}
	.db-project-title {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40%;
    text-align: center;
	}
	.db-responsive-page-title {
		display: none;
	}
	.profile-drop-icon {
		display: block !important;
	}
	.db-profile-section,
	.db-project-commands {
		display: none;
	}
	.phone-hide {
		display: none;
	}
	.db-responsive-page {
		padding-left: 0;
	}
}
.db-dashboard-container.closed {
	transform: translateX(-13rem);
}
.db-responsive-page.closed {
	padding-left: 3rem;
}
.db-dashboard-title.closed,
.db-dashboard-list.closed, {
	visibility: hidden;
}
.db-utilicast-title.closed {
		transform: translateX(-13rem);
	}
.db-dashboard-list-icon.closed,
.db-divider-horizontal.closed {
	visibility: visible;
}
.db-admin-data-dropdown.closed {
		visibility: hidden;
	}

.db-dashboard-container.closed:hover, 
.db-dashboard-title.closed, 
.db-dashboard-list.closed {
	transform: translateX(0);
	visibility: visible;
	z-index: 11;
}
.db-dashboard-container.closed:hover .db-dashboard-list-icon.closed,
.db-dashboard-container.closed:hover .db-divider-horizontal.closed {
	visibility: hidden;
}

.db-page-selected {
	font-weight: bold;
}
.loading-div-container.closed {
	left: calc(50% - 10rem);
}
.loading-div-container {
	display: flex;
	justify-content: center;
	align-items: center;
  width: 30rem;
  height: 20rem;
  position: fixed;
  top: calc(50% - 8rem);
  left: calc(50% - 3rem);
  background: white;
  flex-direction: column;
  border-radius: 1rem;
  z-index: 14;
}
.loading-div-contract {
	display: flex;
	justify-content: center;
	align-items: center;
  width: 25rem;
  height: 15rem;
  position: fixed;
  background: white;
  flex-direction: column;
  border-radius: 1rem;
  z-index: 14;
  top: 45%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.loading-div-text {
	margin: 0;
	padding: 2rem 2rem 0 2rem;
	color: grey;
	text-align: center;
}
.backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 13;
	top: 0;
	left: 0;
	background: rgb(0,0,0,0.25);
}
.dead-center {
	display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.shift {
	padding-top: 0.001rem;
}


