
/*Start of main header code*/
.background-list {
	background-image: linear-gradient(to top, rgb(220,220,220, 0.3) 50%, rgb(40,40,40, 0.3));
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: -1;
}
.db-background-list {
	background-image: linear-gradient(to top, rgb(150,150,150, 0.3) 5%, grey);
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: -1;
}
.db-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	display: none;
}
.db-logo-section {
	height: 2.4rem;
	margin-bottom: 0.32rem;
}
.db-utilicast-title {
	display: flex;
	align-items: center;
	transform: translateX(0);
	transition: transform 0.4s cubic-bezier(0, 0.6, 1, 1);
}
.db-utilicast {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 14.5rem;
}
.db-menu {
	fill: #5c6066;
	cursor: pointer;
}
.db-project-header {
	position: fixed;
	width: 100%;
	z-index: 16;
	height: 56px;
	padding: 1rem;
	color: #5c6066;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	background: #fff;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
}
.db-project-title {
	flex-shrink: 0;
	flex-grow: 0;
	padding: 0 1.5rem;
}
.db-responsive-page-title {
	font-size: 16px;
	margin: 0;
}
.filter,
.filter1 {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0.5rem;
	border-radius: 50% 50%;
	background: #013E7E;
}
.filter-drop,
.filter-drop1 {
	background: white;
	position: absolute;
	top: 54px;
	z-index: 12;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	padding: 1rem;
	color: black;
	box-shadow: 2px 2px 8px grey;
}
.command-button {
	fill: #fff !important;
}
.filter-element {
	font-size: 1rem;
	font-weight: bold;
	padding: 0.5rem 1rem;
	padding-left: 0;
	margin: 0;
	list-style: none;
}
.filter-choice {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-height: 26px;
}
.filter-choice-sow {
	width: 70%;
}
.db-project-search {
	background: #013E7E;
	border-radius: 22px;
	border: none;
	padding: 0.2rem 0.8rem;
	flex-shrink: 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.db-project-search {
	border-radius: 50%;
	padding: 0.5rem 0.5rem;
	flex-grow: 0;
}
.db-project-searcher {
	background: white;
	border-radius: 22px;
	border: none;
	padding: 0.2rem 0.2rem;
	width: 100%;
	font-size: 0.7rem;
	display: none;
	color: black;
	margin: 0 0.5rem;
}
.db-open-search {
	border-radius: 22px;
	padding: 0.2rem 0.8rem;
	flex-grow: 1;
	width: 20rem;
}
.db-open-search:hover {
	transform: scale(1) !important;
}
.db-open-searcher {
	display: block !important;
}
.db-home {
	font-size: 12px;
	margin: 0 .5rem !important;
  font-weight: 300;
}
.home-link,
.home-link:hover {
	text-decoration: none;
	color: #5c6066;
}

.db-project-commands {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-evenly;
	padding: .5rem 0rem;
	flex-shrink: 0;
}
.db-project-commands div {
	margin: 0.5rem;
}
.command-none {
	display: none !important;
}
.db-download {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0.5rem;
	border-radius: 50% 50%;
	background: #013E7E;
}
.db-download-button {
	fill: black;
}
.db-sorter {
	display: flex;
	align-items: center;
	padding: 0.5rem 0rem;
	border-radius: 50% 50%;
	background: #013E7E;
	height: 20px;
	width: 20px;
	justify-content: center;
}
.db-sort-button {
	fill: #fff;
}
.db-project-new {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	background: #F6B924;
	border-color: #F6B924;
	border-radius: 50% 50%;
}
.db-add-button {
	fill: white;
}
.db-project-new:active,
.db-project-new:hover {
	transform: scale(1.14);
	border: none;
	cursor: pointer
}
.db-download:active,
.db-download:hover,
.db-sorter:active,
.db-sorter:hover,
.db-project-search:active,
.db-project-search:hover
.profile-button:active,
.profile-button:hover {
	transform: scale(1.14);
  cursor: pointer
}
.db-command-profile {
	display: flex;
	align-items: center;
}

.db-profile-section {
	
	display: flex;
	align-items: center;
	padding-right: 2rem;
}
.db-divider {
	width: 2px;
	height: 2.5rem;
	background: rgb(190, 190, 190);
	border-radius: 1px;
	margin: 0 0.5rem;
}
.db-profile-section-inner {
	display: flex;
	flex-grow: 1;
	min-width: 97px;
	align-items: center;
}
.db-name-picture {
	background: white;
	border-radius: 50%;
	margin: 0 1rem;
	height: 2.5rem !important;
	width: 2.5rem !important;
}
.db-name-dropdown {
	margin: 0 1rem;
	fill: #5c6066;
}
.logout {
	width: 2.2rem;
	height: 2.2rem;
	margin: 0 1rem;
	padding-right: 0.3rem;
	fill: white;
  border-radius: 50%;
  color: white;
  background: #93989b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logout:hover {
	transform: scale(1.02);
}
.filter-sow-input-div {
	display: flex;
	width: 50%;
	justify-content: space-between;
	margin: 0 !important;
}
.filter-sow-input {
	display: flex;
	align-items: flex-end;
	margin: 0 !important;
}
.filter-dropdown-div .css-bg1rzq-control {
	width: 25rem;
}
 .filter-dropdown-div .select__control {
   width: 25rem;
}
.filter-dropdown-div .css-151xaom-placeholder {
	margin: 0;
	margin-left: 0.5rem;
}

.filter-dropdown-div .css-1wy0on6,
.filter-dropdown-div .select__indicators,
.filter-dropdown-div .css-1g6gooi,
.filter-dropdown-div .select__input {
	height: 2rem;
  padding: 0;
  margin: 0;
}

.filter-dropdown-div .select__multi-value__label {
	font-size: 6px;
	padding: 0;
}

.filter-dropdown-div .select__multi-value {
	height: 2rem;
	margin: 0.2rem;
}
.filter-dropdown-div .select__multi-value__remove {
	display: none;
}
.filter-dropdown-div .css-1hwfws3,
.filter-dropdown-div .select__value-container {
	margin: 0;
	padding: 0;
}
.db-project-commands1 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 0.5rem 1rem;
	flex-shrink: 0;
}
.db-project-commands1 div {
	margin: 0.5rem;
}
/*End of Main formatted Header*/
/*Code for profile drop down for mobile*/
.profile-drop-icon {
	padding: 0 0.4rem;
	margin-right: 1.5rem;
	zoom: 1.6;
	background: white;
	color: black;
	fill: #013E7E;
	border-radius: 2px;
	display: none;
	cursor: pointer;
	border: 0.5px solid rgb(190, 190, 190);
	padding: 0 1rem;
}

.project-commands1 {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-evenly;
	padding: 0.5rem 1rem;
	flex-shrink: 0;
}
.project-commands1 div {
	margin: 0 0.25rem;
}
.profile-dropdown-logout {
	display: flex;
	justify-content: flex-end;
}
.profile-logout-drop {
	padding: 0.75rem 0.5rem;
	position: absolute;
	top: 60px;
	border-radius: 2px;
	background: white;
	z-index: 15;
	border: 1px solid rgba(0,0,0,0.15);
	min-width: 115px;
}
.logout-wide {
	display: flex;
	align-items: center;
	padding: 0 1rem;
	justify-content: flex-start;
	fill: #5C6066;
}
.profile-logout-img {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: -1;
	left: 0;
	opacity: 0.8;
	background-image: url(https://www.utilicast.com/media/iStock_000004352370_Full-1.jpg);
	background-size: cover;
	background-position: left 60% top 80%;
	border-radius: 8px;
	filter: grayscale(50%);
}
.profile-button {
	padding: 0.25rem 0.5rem;
	background: white;
	color: rgb(0,0,0,0.7);
	border-radius: 12px;
	margin-left: 2rem;
	font-size: 1rem;
}
.profile-button:hover {
	text-decoration: none;
	color: rgb(0,0,0,0.7);
}
.profile-button-container {
	display: flex;
	align-items: center;
}
@media(max-width: 24rem) {
	.db-open-search {
		margin-right: -8rem !important;
		width: auto;
		height: 20px;
	}
	.db-open-search {
		margin-right: -8rem !important;
		width: 20rem;
	}
}
@media(max-width: 900px) {
	.db-responsive-page-title-mobile {
		display: block !important;
		font-size: 1.5rem;
	}
	.db-project-title {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40%;
    text-align: center;
	}
	.db-responsive-page-title {
		display: none;
	}
	.profile-drop-icon {
		display: block !important;
	}
	.db-profile-section,
	.db-project-commands {
		display: none;
	}
}
@media(min-width: 900px) {
	.profile-drop {
		display: none !important;
	}
}
@media only screen and (max-device-width: 812px) {
	.phone-hide {
		display: none;
	}
	.db-responsive-page {
		padding-left: 0;
	}
}


.profile-drop {
	display: flex;
	justify-content: flex-end;
	top: 56px;
	z-index: 200;
	background: #fff;
	align-items: center;
	right: 0;
	color: #5c6066;
	width: 100%;
	height: 56px;
	position: sticky;
	box-shadow: 0 4px 6px -2px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);;
}


/*End of mobile header code*/